import { Card, Typography, Empty, Tag } from 'antd';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { useState } from 'react';
const { Title } = Typography;

const NewsTile = ({
  newsData,
  setPageIdDisplayed,
  setArticleIdDisplayed,
  searchTerm,
}) => {
  const [isFavorite, setIsFavorite] = useState(false);

  const handleCardClick = () => {
    // console.log(newsData.articleID);
    // console.log(newsData.pageNum);
    setArticleIdDisplayed(newsData.articleID);
    setPageIdDisplayed(newsData.pageNum);
  };

  const handleFavoriteClick = () => {
    setIsFavorite(!isFavorite);
  };

  const highlightSearchTerm = (text, searchTerm) => {
    if (!searchTerm) {
      return text;
    }

    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const highlightedText = text.replace(
      regex,
      '<mark style="background-color: yellow;">$1</mark>',
    );

    return highlightedText;
  };

  return (
    <Card
      onClick={handleCardClick}
      style={{ boxShadow: '1px 3px 10px 0px rgba(0, 0, 0, 0.1)' }}
      className="mb-5">
      <div className="d-flex align-items-center mb-4">
        <Title level={4} className="mb-0 mr-3">
          {newsData.fullTitle}
        </Title>
        <Tag>{newsData.pageNum}</Tag>
        {isFavorite ? (
          <StarFilled
            style={{ color: 'yellow' }}
            onClick={handleFavoriteClick}
          />
        ) : (
          <StarOutlined
            style={{ color: 'lightgrey' }}
            onClick={handleFavoriteClick}
          />
        )}
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: highlightSearchTerm(newsData.abstractText, searchTerm),
        }}
      />
    </Card>
  );
};

export default NewsTile;
