let startDate = new Date(1876, 0, 2); // January 2nd, 1950
let endDate = new Date(); // today's date

let dates = [];
let currentDate = startDate;

while (currentDate <= endDate) {
  let dateString = currentDate.toISOString().split('T')[0];
  dates.push(dateString);
  currentDate.setDate(currentDate.getDate() + 1);
}

export default dates;
