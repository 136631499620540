import logo from '../logo.svg';
import React, {useState, useEffect} from 'react';
import '../App.css';

const Favorites = () => {

  return(
      <>
        <div>
            <p>
              Favorites - Coming soon
            </p>
        </div>
      </>
  )
}

export default Favorites;