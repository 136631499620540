import { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import './Globals.css';
import Home from './page/Home';
import Search from './page/Search';
import Login from './page/Login';
import Favorites from './page/Favorites';
import { Link } from 'react-router-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AntLayout from '../src/components/widgets/AntLayout';
import PrivateRoute from './components/widgets/PrivateRoute';

function Root() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />\
        <Route
          exact
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/search"
          element={
            <PrivateRoute>
              <Search />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/favorites"
          element={
            <PrivateRoute>
              <Favorites />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  );
}

export default App;
