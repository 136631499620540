import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AntLayout from './AntLayout';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
      // console.log(user.email);
    });

    return unsubscribe;
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return <AntLayout>{children}</AntLayout>;
};

export default PrivateRoute;

// PARTIALLY WORKING
// import {
//   Routes,
//   Route,
//   NavLink,
//   Navigate,
//   useNavigate,
//   useLocation,
// } from 'react-router-dom';

// import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

// const PrivateRoute = ({ children }) => {
//   const location = useLocation();

//   const auth = getAuth();
//   const user = auth.currentUser;

//   if (!user) {
//     return <Navigate to="/login" replace state={{ from: location }} />;
//   }

//   return children;
// };

// export default PrivateRoute;
