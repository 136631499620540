import { useState, useEffect, useRef } from 'react';
import { Avatar, Divider, List, Affix, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import defaultDates from '../utilities/dates';

const NewsDays = ({ dayMonthYear, setDayMonthYear, datesData }) => {
  const dates = datesData || defaultDates;

  return (
    <Affix>
      <List
        dataSource={dates}
        renderItem={(date) => (
          <List.Item
            onClick={() => setDayMonthYear(date)}
            style={{ background: dayMonthYear === date ? '#e8e8e8' : 'white' }}>
            {date}
          </List.Item>
        )}
        style={{ height: 'calc(100vh - 64px)', overflowY: 'auto' }}
      />
    </Affix>
  );
};
export default NewsDays;
