import { Form, Input, Button, Card, message } from 'antd';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from "firebase/auth";

const auth = getAuth();
setPersistence(auth, browserLocalPersistence);

function Login() {
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async (values) => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, values.email, values.password);
      const origin = location.state?.from?.pathname || '/home';
      // console.log('location', location.state?.from?.pathname)
      navigate(origin);
      // console.log('login SUCCESS')
    } catch (error) {
      setLoginError(true);
      message.error(error.message);
    }
    setLoading(false);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Card title="Login" style={{ width: 400 }}>
        <Form onFinish={handleLogin}>
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Please enter a valid email address',
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please enter your password' }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} style={{ width: '100%' }}>
              Login
            </Button>
          </Form.Item>
        </Form>
        {loginError && (
          <p style={{ color: 'red', textAlign: 'center' }}>Login not successful. Please try again.</p>
        )}
      </Card>
    </div>
  );
}

export default Login;