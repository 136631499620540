import React, { useState, useEffect } from 'react';
import { Switch, Skeleton, Image } from 'antd';

const ArticleMedia = ({
  dayMonthYear,
  newsData,
  pageIdDisplayed,
  articleIdDisplayed,
}) => {
  // new url uses cloud function to get image from http and serves as https
  const url = `https://us-central1-news-archive-28cc7.cloudfunctions.net/getImageFromUrl?imageId=${articleIdDisplayed}`;
  // url to open the lastampa website on a specific page
  const urlNewspaperArchive = `http://www.archiviolastampa.it/component/option,com_lastampa/task,search/mod,libera/action,viewer/Itemid,3/page,${pageIdDisplayed}/articleid,${articleIdDisplayed}/anews,true/`;

  const openSource = () => {
    console.log(`opening!`);
    window.open(urlNewspaperArchive, '_blank');
  };

  const [switchOn, setSwitchOn] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSwitchChange = (checked) => {
    setSwitchOn(checked);
    setLoading(true);
  };

  useEffect(() => {
    if (switchOn) {
      setLoading(true);
      // console.log('articleIdDisplayed from media', articleIdDisplayed);
      // console.log('pageIdDisplayed from media', pageIdDisplayed);
    }
  }, [articleIdDisplayed, pageIdDisplayed, dayMonthYear, switchOn]);

  useEffect(() => {
    console.log('i am loading');
  }, [loading]);

  const disabled = !pageIdDisplayed || !articleIdDisplayed || !newsData;

  return (
    <>
      <div>
        <span style={{ textDecoration: 'underline' }} onClick={openSource}>
          Open in LaStampa
        </span>
        <span className="ml-4">
          <Switch
            checked={switchOn}
            disabled={disabled}
            onChange={handleSwitchChange}
            style={{ backgroundColor: switchOn ? 'black' : '#c7c7c7' }}
          />
        </span>
      </div>

      {switchOn && !disabled && (
        <div className="mt-4">
          {loading && (
            <Skeleton.Image style={{ width: 400, height: 600 }} active />
          )}
          <Image
            src={url}
            style={{ display: loading ? 'none' : 'block' }}
            onLoad={() => setLoading(false)}
          />
        </div>
      )}
    </>
  );
};

export default ArticleMedia;
