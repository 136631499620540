import { useState } from 'react';
import { Breadcrumb, Layout, Menu, theme, Input } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';
import { getAuth, signOut } from 'firebase/auth';
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import UserMenu from './UserMenu';

const { Header, Content, Footer } = Layout;

const items = [
  {
    label: 'Home',
    key: 'home',
  },
  {
    label: 'Favorites',
    key: 'favorites',
  },
];

const AntLayout = ({ children }) => {
  const params = new URLSearchParams(window.location.search);
  const initialSearchTerm = params.get('searchTerm');

  const Search = Input.Search;

  const [current, setCurrent] = useState('home');
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  const navigate = useNavigate();

  const onSearchClick = (searchTerm) => {
    // console.log('search ', searchTerm);
    navigate(`/search?searchTerm=${encodeURIComponent(searchTerm)}`);
  };

  const onMenuClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
    navigate(`/${e.key}`);
  };

  return (
    <Layout>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          backgroundColor: 'white',
          boxShadow: '0px 0px 8px rgba(0,0,0,.4)',
          paddingLeft: '20px',
          paddingRight: '20px',
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <div
            style={{
              fontSize: '1.5em',
              fontWeight: 'bold',
              marginLeft: '0px',
              marginRight: '30px',
            }}>
            News Archive
          </div>
          <Menu
            onClick={onMenuClick}
            selectedKeys={[current]}
            mode="horizontal"
            items={items}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Search
            placeholder="type here"
            style={{ width: 200, marginRight: 10 }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onSearch={onSearchClick}
          />
          <UserMenu />
        </div>
      </Header>
      <Content className="site-layout" style={{ padding: '0px' }}>
        {children}
      </Content>
    </Layout>
  );
};

export default AntLayout;
