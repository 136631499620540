import { useEffect, useState } from "react";
import logo from '../logo.svg';
import '../App.css';
import { Button } from 'antd';
import 'antd/dist/reset.css';
import { Col, Row } from 'antd';
import Articles from '../components/Articles'
import NewsDays from '../components/NewsDays';
import ArticleMedia from '../components/ArticleMedia';
import { db } from '../firebase'; // update with your path to firestore config
import { collection, getDocs } from "firebase/firestore";
import dates from "../utilities/dates";


function Home() {

  const params = new URLSearchParams(window.location.search);
  const initialDate = params.get('selectedDate') || dates[0];

  const [dayMonthYear, setDayMonthYear] = useState(initialDate);
  const [newsData, setNewsData] = useState([]);

  const [pageIdDisplayed, setPageIdDisplayed] = useState(null);
  const [articleIdDisplayed, setArticleIdDisplayed] = useState(null);

  
  useEffect(() => {
    params.set('selectedDate', dayMonthYear);
    window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
  }, [dayMonthYear]);
  

  return (
    <Row style={{backgroundColor: 'white'}}>
      <Col span={5} className="rightBoxShadow">
        <NewsDays dayMonthYear={dayMonthYear} setDayMonthYear={setDayMonthYear}/>
      </Col>
      <Col span={10} className="rightBoxShadow " style={{
          height: "calc(100vh - 64px)",
          overflow: 'auto'}}> 
        <div className='ml-6 mt-6 mr-6'>
          <Articles dayMonthYear={dayMonthYear} newsData={newsData} setNewsData={setNewsData} setPageIdDisplayed={setPageIdDisplayed} setArticleIdDisplayed={setArticleIdDisplayed} />
        </div>
      </Col>
      <Col span={9} style={{  }}>
        <div className='ml-6 mt-6 mr-6'>
          <ArticleMedia dayMonthYear={dayMonthYear} newsData={newsData} pageIdDisplayed={pageIdDisplayed} articleIdDisplayed={articleIdDisplayed} />
        </div>
      </Col>
    </Row>
    
  );
}

export default Home;