import React, { useState, useEffect } from 'react';
import { StarOutlined, StarFilled, StarTwoTone } from '@ant-design/icons';

import { db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

import NewsTile from './NewsTile';

import { Skeleton, Typography, Empty } from 'antd';

const Articles = ({
  dayMonthYear,
  newsData,
  setNewsData,
  newsDataFromSearch,
  setPageIdDisplayed,
  setArticleIdDisplayed,
  searchTerm,
}) => {
  // Note: articleIDs are used only from search page or anyway in case you want to render only those, and not all the articles for that given day
  const [articleIDs, setArticleIDs] = useState([]);

  const extractArticleIDs = (data, date) => {
    // console.log(data);
    // console.log(date);
    return data.filter((item) => item.day === date);
    // .map((item) => item.articleID);
  };

  useEffect(() => {
    setPageIdDisplayed(null);
    setArticleIdDisplayed(null);
    setNewsData([]);
    // console.log('starting dayMonthYear ', dayMonthYear);
    if (newsDataFromSearch) {
      const newArticleIDs = extractArticleIDs(newsDataFromSearch, dayMonthYear);
      setArticleIDs(newArticleIDs);
    }
    // console.log(articleIDs);
  }, [dayMonthYear, newsDataFromSearch]);

  // Additional useEffect to log the updated articleIDs state
  useEffect(() => {
    // console.log(articleIDs);
    if (articleIDs.length > 0) {
      setPageIdDisplayed(articleIDs[0].pageNum);
      setArticleIdDisplayed(articleIDs[0].articleID);
    }
  }, [articleIDs]);

  useEffect(() => {
    if (newsData && newsData.length > 0) {
      setPageIdDisplayed(newsData[0]?.pageNumber);
      setArticleIdDisplayed(newsData[0]?.response?.docs[0]?.articleID);
    }
  }, [newsData]);

  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const fetchNews = async () => {
  //     let q = query(
  //       collection(db, 'newspapers_pages'),
  //       where('day', '==', dayMonthYear),
  //     );
  //     const classesCollection = await getDocs(q);
  //     const fetchedData = [];
  //     classesCollection.forEach((doc) => {
  //       console.log(doc.data());
  //       fetchedData.push(doc.data());
  //     });
  //     setNewsData(fetchedData);
  //     setLoading(false);
  //     // console.log(dayMonthYear);
  //     // console.log(fetchedData);
  //   };

  //   fetchNews();
  // }, [dayMonthYear, setNewsData]);

  useEffect(() => {
    if (!newsData.length && !articleIDs.length) {
      const fetchNews = async () => {
        setLoading(true);
        const q = query(
          collection(db, 'newspapers_pages'),
          where('day', '==', dayMonthYear),
        );
        const classesCollection = await getDocs(q);
        const fetchedData = [];
        classesCollection.forEach((doc) => {
          fetchedData.push(doc.data());
        });
        setNewsData(fetchedData);
        // console.log('fetchedData');
        // console.log(fetchedData);
        // console.log('fetchedData');
        // if (
        //   newsData &&
        //   newsData[0] &&
        //   newsData[0].docs &&
        //   newsData[0].docs[0]
        // ) {
        //   console.log('articleId, ', newsData[0]?.response?.docs[0].articleID);
        //   console.log('pageId, ', newsData[0].pageNumber);
        // }
        setLoading(false);
      };

      fetchNews();
    } else {
      setLoading(false);
    }
  }, [dayMonthYear, articleIDs]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
        }}>
        {loading ? (
          Array.from({ length: 3 }).map((_, index) => (
            <Skeleton
              key={index}
              style={{ width: 300, height: 200, margin: 16 }}
              active
            />
          ))
        ) : // ) : newsData.length > 0 ? (
        //   newsData[0].response?.docs?.map((newsPage) =>
        //     <NewsTile key={newsPage.articleID} newsData={newsPage} />
        //   )
        // ) : (
        newsData.length > 0 || articleIDs.length > 0 ? (
          (articleIDs.length > 0 ? articleIDs : newsData[0].response?.docs).map(
            (newsPage) => (
              <NewsTile
                key={newsPage.articleID}
                newsData={newsPage}
                setPageIdDisplayed={setPageIdDisplayed}
                setArticleIdDisplayed={setArticleIdDisplayed}
                searchTerm={searchTerm}
              />
            ),
          )
        ) : (
          <Empty />
        )}
      </div>
    </>
  );
};

export default Articles;
