import { useEffect, useState } from 'react';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';

const auth = getAuth();
const user = auth.currentUser;

const UserMenu = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleLogOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate('/login');
      })
      .catch((error) => {
        // An error happened.
        console.error(error);
      });
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
      // console.log(user.email);
    });

    return unsubscribe;
  }, []);

  if (loading) {
    return <></>;
  }

  return (
    <>
      <Tooltip title="log out">
        <Button
          type="text"
          shape="circle"
          icon={<LogoutOutlined />}
          onClick={handleLogOut}
        />
      </Tooltip>
    </>
  );
};

export default UserMenu;
